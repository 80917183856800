<template>
  <div class="key-view">
    <h1>Game Key</h1>
    <div class="seed-input">
      <label for="seed-input">Seed:</label>
      <input 
        id="seed-input" 
        v-model="localSeed" 
        @input="updateSeed"
        placeholder="Enter seed"
      >
    </div>
    <div class="key-grid" :class="{ 'red-border': moreRed, 'blue-border': moreBlue }">
      <div 
        v-for="(color, index) in keyColors" 
        :key="index" 
        class="key-square"
        :class="color"
      ></div>
    </div>
  </div>
</template>

<script>
import seedrandom from 'seedrandom'

export default {
  name: 'KeyView',
  props: ['seed'],
  data() {
    return {
      localSeed: this.seed || '',
      keyColors: [],
      moreRed: false,
      moreBlue: false
    }
  },
  watch: {
    seed(newSeed) {
      if (newSeed !== this.localSeed) {
        this.localSeed = newSeed
        this.generateKeyColors()
      }
    }
  },
  created() {
    if (!this.localSeed) {
      this.localSeed = this.generateRandomSeed()
    }
    this.generateKeyColors()
  },
  methods: {
    generateRandomSeed() {
      return Math.random().toString(36).substring(2, 7)
    },
    updateSeed() {
      this.$emit('update:seed', this.localSeed)
      this.generateKeyColors()
    },
    generateKeyColors() {
      const rng = seedrandom(this.localSeed)
      this.moreRed = rng() < 0.5
      this.moreBlue = !this.moreRed

      const colors = [
        ...Array(this.moreRed ? 9 : 8).fill('red'),
        ...Array(this.moreBlue ? 9 : 8).fill('blue'),
        ...Array(7).fill('gray'),
        'black'
      ]
      
      this.keyColors = this.shuffleArray(colors, rng)
    },
    shuffleArray(array, rng) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(rng() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]
      }
      return array
    }
  }
}
</script>

<style scoped>
.key-view {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.seed-input {
  margin-bottom: 20px;
}

.seed-input label {
  margin-right: 10px;
}

.seed-input input {
  padding: 5px;
  font-size: 16px;
}

.key-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  max-width: 300px;
  margin: 20px auto;
  padding: 10px;
  border: 6px solid;
}

.red-border {
  border-color: #e74c3c;
}

.blue-border {
  border-color: #3498db;
}

.key-square {
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
}

.red { background-color: #e74c3c; }
.blue { background-color: #3498db; }
.gray { background-color: #95a5a6; }
.black { background-color: #2c3e50; }
</style>