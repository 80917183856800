<template>
  <div 
    class="word-card" 
    :class="colorClass" 
    @click="cycleColorForward"
    @contextmenu.prevent="cycleColorBackward"
  >
    {{ word }}
  </div>
</template>

<script>
export default {
  name: 'WordCard',
  props: {
    word: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      colorState: 0
    }
  },
  computed: {
    colorClass() {
      const colors = ['white', 'gray', 'blue', 'red', 'black']
      return colors[this.colorState]
    }
  },
  methods: {
    cycleColorForward() {
      this.colorState = (this.colorState + 1) % 5
    },
    cycleColorBackward() {
      this.colorState = (this.colorState - 1 + 5) % 5
    }
  }
}
</script>

<style scoped>
.word-card {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  user-select: none;
}

.white {
  background-color: #ffffff;
  color: #000000;
}

.gray {
  background-color: #dfd2c2;
  color: black;
}

.blue {
  background-color: #055793;
  color: #ffffff;
}

.red {
  background-color: #eb421a;
  color: #ffffff;
}

.black {
  background-color: #171509;
  color: #ffffff;
}
</style>