import { createRouter, createWebHistory } from "vue-router";
import HomeView from "./Home.vue";
import KeyView from "./components/Key.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: HomeView,
        props: (route) => ({ seed: route.query.seed }),
    },
    {
        path: "/key",
        name: "Key",
        component: KeyView,
        props: (route) => ({ seed: route.query.seed }),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
