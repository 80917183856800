<template>
    <div id="app">
        <h1>{{ title }}</h1>
        <!-- <p>Seed: {{ localSeed }}</p> -->
        <div class="grid">
            <WordCard v-for="(word, index) in selectedWords" :key="index" :word="word" />
        </div>
    </div>
</template>

<script>
import WordCard from "./components/WordCard.vue";
import seedrandom from "seedrandom";

export default {
    name: "HomeView",
    props: ["seed"],
    components: {
        WordCard,
    },
    data() {
        return {
            title: "Code Names",
            localSeed: this.seed || "",
            wordBank: [
                "AFRICA",
                "AGENT",
                "AIR",
                "ALIEN",
                "ALPS",
                "AMAZON",
                "AMBULANCE",
                "AMERICA",
                "ANGEL",
                "ANTARCTICA",
                "APPLE",
                "ARM",
                "ATLANTIS",
                "AUSTRALIA",
                "AZTEC",
                "BACK",
                "BALL",
                "BAND",
                "BANK",
                "BAR",
                "BARK",
                "BAT",
                "BATTERY",
                "BEACH",
                "BEAR",
                "BEAT",
                "BED",
                "BEIJING",
                "BELL",
                "BELT",
                "BERLIN",
                "BERMUDA",
                "BERRY",
                "BILL",
                "BLOCK",
                "BOARD",
                "BOLT",
                "BOMB",
                "BOND",
                "BOOM",
                "BOOT",
                "BOTTLE",
                "BOW",
                "BOX",
                "BRIDGE",
                "BRUSH",
                "BUCK",
                "BUFFALO",
                "BUG",
                "BUGLE",
                "BUTTON",
                "CALF",
                "CANADA",
                "CAP",
                "CAPITAL",
                "CAR",
                "CARD",
                "CARROT",
                "CASINO",
                "CAST",
                "CAT",
                "CELL",
                "CENTAUR",
                "CENTER",
                "CHAIR",
                "CHANGE",
                "CHARGE",
                "CHECK",
                "CHEST",
                "CHICK",
                "CHINA",
                "CHOCOLATE",
                "CHURCH",
                "CIRCLE",
                "CLIFF",
                "CLOAK",
                "CLUB",
                "CODE",
                "COLD",
                "COMIC",
                "COMPOUND",
                "CONCERT",
                "CONDUCTOR",
                "CONTRACT",
                "COOK",
                "COPPER",
                "COTTON",
                "COURT",
                "COVER",
                "CRANE",
                "CRASH",
                "CRICKET",
                "CROSS",
                "CROWN",
                "CYCLE",
                "CZECH",
                "DANCE",
                "DATE",
                "DAY",
                "DEATH",
                "DECK",
                "DEGREE",
                "DIAMOND",
                "DICE",
                "DINOSAUR",
                "DISEASE",
                "DOCTOR",
                "DOG",
                "DRAFT",
                "DRAGON",
                "DRESS",
                "DRILL",
                "DROP",
                "DUCK",
                "DWARF",
                "EAGLE",
                "EGYPT",
                "EMBASSY",
                "ENGINE",
                "ENGLAND",
                "EUROPE",
                "EYE",
                "FACE",
                "FAIR",
                "FALL",
                "FAN",
                "FENCE",
                "FIELD",
                "FIGHTER",
                "FIGURE",
                "FILE",
                "FILM",
                "FIRE",
                "FISH",
                "FLUTE",
                "FLY",
                "FOOT",
                "FORCE",
                "FOREST",
                "FORK",
                "FRANCE",
                "GAME",
                "GAS",
                "GENIUS",
                "GERMANY",
                "GHOST",
                "GIANT",
                "GLASS",
                "GLOVE",
                "GOLD",
                "GRACE",
                "GRASS",
                "GREECE",
                "GREEN",
                "GROUND",
                "HAM",
                "HAND",
                "HAWK",
                "HEAD",
                "HEART",
                "HELICOPTER",
                "HIMALAYAS",
                "HOLE",
                "HOLLYWOOD",
                "HONEY",
                "HOOD",
                "HOOK",
                "HORN",
                "HORSE",
                "HORSESHOE",
                "HOSPITAL",
                "HOTEL",
                "ICE",
                "ICE CREAM",
                "INDIA",
                "IRON",
                "IVORY",
                "JACK",
                "JAM",
                "JET",
                "JUPITER",
                "KANGAROO",
                "KETCHUP",
                "KEY",
                "KID",
                "KING",
                "KIWI",
                "KNIFE",
                "KNIGHT",
                "LAB",
                "LAP",
                "LASER",
                "LAWYER",
                "LEAD",
                "LEMON",
                "LEPRECHAUN",
                "LIFE",
                "LIGHT",
                "LIMOUSINE",
                "LINE",
                "LINK",
                "LION",
                "LITTER",
                "LOCH NESS",
                "LOCK",
                "LOG",
                "LONDON",
                "LUCK",
                "MAIL",
                "MAMMOTH",
                "MAPLE",
                "MARBLE",
                "MARCH",
                "MASS",
                "MATCH",
                "MERCURY",
                "MEXICO",
                "MICROSCOPE",
                "MILLIONAIRE",
                "MINE",
                "MINT",
                "MISSILE",
                "MODEL",
                "MOLE",
                "MOON",
                "MOSCOW",
                "MOUNT",
                "MOUSE",
                "MOUTH",
                "MUG",
                "NAIL",
                "NEEDLE",
                "NET",
                "NEW YORK",
                "NIGHT",
                "NINJA",
                "NOTE",
                "NOVEL",
                "NURSE",
                "NUT",
                "OCTOPUS",
                "OIL",
                "OLIVE",
                "OLYMPUS",
                "OPERA",
                "ORANGE",
                "ORGAN",
                "PALM",
                "PAN",
                "PANTS",
                "PAPER",
                "PARACHUTE",
                "PARK",
                "PART",
                "PASS",
                "PASTE",
                "PENGUIN",
                "PHOENIX",
                "PIANO",
                "PIE",
                "PILOT",
                "PIN",
                "PIPE",
                "PIRATE",
                "PISTOL",
                "PIT",
                "PITCH",
                "PLANE",
                "PLASTIC",
                "PLATE",
                "PLATYPUS",
                "PLAY",
                "PLOT",
                "POINT",
                "POISON",
                "POLE",
                "POLICE",
                "POOL",
                "PORT",
                "POST",
                "POUND",
                "PRESS",
                "PRINCESS",
                "PUMPKIN",
                "PUPIL",
                "PYRAMID",
                "QUEEN",
                "RABBIT",
                "RACKET",
                "RAY",
                "REVOLUTION",
                "RING",
                "ROBIN",
                "ROBOT",
                "ROCK",
                "ROME",
                "ROOT",
                "ROSE",
                "ROULETTE",
                "ROUND",
                "ROW",
                "RULER",
                "SATELLITE",
                "SATURN",
                "SCALE",
                "SCHOOL",
                "SCIENTIST",
                "SCORPION",
                "SCREEN",
                "SCUBA DIVER",
                "SEAL",
                "SERVER",
                "SHADOW",
                "SHAKESPEARE",
                "SHARK",
                "SHIP",
                "SHOE",
                "SHOP",
                "SHOT",
                "SINK",
                "SKYSCRAPER",
                "SLIP",
                "SLUG",
                "SMUGGLER",
                "SNOW",
                "SNOWMAN",
                "SOCK",
                "SOLDIER",
                "SOUL",
                "SOUND",
                "SPACE",
                "SPELL",
                "SPIDER",
                "SPIKE",
                "SPINE",
                "SPOT",
                "SPRING",
                "SPY",
                "SQUARE",
                "STADIUM",
                "STAFF",
                "STAR",
                "STATE",
                "STICK",
                "STOCK",
                "STRAW",
                "STREAM",
                "STRIKE",
                "STRING",
                "SUB",
                "SUIT",
                "SUPERHERO",
                "SWING",
                "SWITCH",
                "TABLE",
                "TABLET",
                "TAG",
                "TAIL",
                "TAP",
                "TEACHER",
                "TELESCOPE",
                "TEMPLE",
                "THEATER",
                "THIEF",
                "THUMB",
                "TICK",
                "TIE",
                "TIME",
                "TOKYO",
                "TOOTH",
                "TORCH",
                "TOWER",
                "TRACK",
                "TRAIN",
                "TRIANGLE",
                "TRIP",
                "TRUNK",
                "TUBE",
                "TURKEY",
                "UNDERTAKER",
                "UNICORN",
                "VACUUM",
                "VAN",
                "VET",
                "WAKE",
                "WALL",
                "WAR",
                "WASHER",
                "WASHINGTON",
                "WATCH",
                "WATER",
                "WAVE",
                "WEB",
                "WELL",
                "WHALE",
                "WHIP",
                "WIND",
                "WITCH",
                "WORM",
                "YARD",
            ],
            selectedWords: [],
        };
    },
    watch: {
        localSeed(newSeed) {
            this.$emit("update:seed", newSeed);
        },
    },
    created() {
        if (!this.localSeed) {
            this.localSeed = this.generateRandomSeed();
        }
        this.$emit("update:seed", this.localSeed);
        this.selectRandomWords();
    },
    methods: {
        generateRandomSeed() {
            return Math.random().toString(36).substring(2, 7);
        },
        selectRandomWords() {
            const rng = seedrandom(this.localSeed);
            const shuffled = [...this.wordBank].sort(() => rng() - 0.5);
            this.selectedWords = shuffled.slice(0, 25);
        },
    },
};
</script>

<style>
#app {
    font-family: Arial, sans-serif;
    text-align: center;
    margin-top: 20px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    max-width: 600px;
    margin: 0 auto;
}
</style>
