<template>
  <div id="app">
    <nav>
      <router-link :to="{ name: 'Home', query: { seed: currentSeed } }">Home</router-link> |
      <router-link :to="{ name: 'Key', query: { seed: currentSeed } }">Key</router-link>
    </nav>
    <router-view @update:seed="updateSeed"></router-view>
  </div>
</template>

<script>
export default {
  name: 'AppLayout',
  data() {
    return {
      currentSeed: ''
    }
  },
  created() {
    this.currentSeed = this.$route.query.seed || this.generateRandomSeed()
  },
  methods: {
    updateSeed(newSeed) {
      this.currentSeed = newSeed
    },
    generateRandomSeed() {
      return Math.random().toString(36).substring(2, 15)
    }
  }
}
</script>